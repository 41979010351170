import Vue from "vue";
import Component from "vue-class-component";
import { MarketAreaGetDto } from "../api/types";
import { marketArea } from "../store";
import { MarketAreaState } from "../store/marketarea/types";

@Component
export class AreaMixin extends Vue {
  @marketArea.State(MarketAreaState.initialMarkedArea)
  initialMarkedArea!: MarketAreaGetDto;
}
