

















































































































































































































import Component, { mixins } from "vue-class-component";
import { DataTableHeader } from "vuetify";
import { AreaDto, AreaType, GetMarketAreaGroupDto } from "../../api/types";
import { AreaMixin } from "../../mixins/area";
import { RoleMixin } from "../../mixins/role";
import { formatNumber } from "../../plugins/formatter";
import { marketArea } from "../../store";
import {
  GroupedArea,
  MarketAreaActions,
  MarketAreaGetters,
  MarketAreaState,
} from "../../store/marketarea/types";

@Component({})
export default class SubmarketList extends mixins(RoleMixin, AreaMixin) {
  groupInEdit: number | null = null;
  newGroupName: string = "";
  selectedRows: AreaDto[] = [];

  @marketArea.Getter(MarketAreaGetters.marketAreaGroups)
  marketAreaGroups!: GetMarketAreaGroupDto[];

  @marketArea.Getter(MarketAreaGetters.marketAreaGroupById)
  marketAreaGroupById!: (id: number) => GetMarketAreaGroupDto;

  @marketArea.Action(MarketAreaActions.deleteMarketAreaGroup)
  deleteMarketAreaGroup!: (id: number) => void;

  @marketArea.Getter(MarketAreaGetters.areasWithGroup)
  areasWithGroup!: GroupedArea[];

  @marketArea.Action(MarketAreaActions.toggleSelectedArea)
  toggleSelectedArea!: (payload: { area: AreaDto; delete: boolean }) => boolean;

  @marketArea.Action(MarketAreaActions.renameMarketAreaGroup)
  renameMarketAreaGroup!: (payload: { id: number; newName: string }) => void;

  @marketArea.Action(MarketAreaActions.createMarketAreaGroup)
  createMarketAreaGroup!: () => void;

  @marketArea.Action(MarketAreaActions.toggleHoverArea)
  highlightItem!: (payload: { area: AreaDto; highlight: boolean }) => void;

  @marketArea.Action(MarketAreaActions.moveAreasToGroup)
  moveAreasToGroup!: (payload: {
    groupId: number;
    areas: AreaDto[];
  }) => boolean;

  @marketArea.State(MarketAreaState.showEvalAmount)
  showEvalAmount!: boolean;

  @marketArea.State(MarketAreaState.showTraffic)
  showTraffic!: boolean;

  @marketArea.State(MarketAreaState.activeArea)
  activeArea!: AreaType;

  toggleRowSelection(item: AreaDto) {
    const idx = this.selectedRows.findIndex((area) => area.kgs === item.kgs);
    if (idx === -1) {
      this.selectedRows.push(item);
    } else {
      this.selectedRows.splice(idx, 1);
    }
  }

  moveToGroup(groupId: number) {
    this.moveAreasToGroup({ groupId, areas: this.selectedRows });
    this.selectedRows = [];
  }

  saveGroupName() {
    if (this.groupInEdit !== null) {
      this.renameMarketAreaGroup({
        id: this.groupInEdit,
        newName: this.newGroupName,
      });
      this.groupInEdit = null;
    }
  }

  showEditGroupName(group: number) {
    this.groupInEdit = group;
    this.newGroupName = this.marketAreaGroupById(group).title;
  }

  async removeGroup(group: number) {
    const res = await this.$confirm(
      "Alle ausgewählten Marktgebiete werden in die Standardgruppe verschoben",
      {
        title: "Gruppe löschen?",
        buttonTrueText: "OK",
        buttonFalseText: "Abbrechen",
      },
    );

    if (res) {
      this.deleteMarketAreaGroup(group);
    }
  }

  groupingFunction(
    items: GetMarketAreaGroupDto[],
    groupBy: string[],
    groupDesc: boolean[],
  ) {
    return items.map((val) => {
      return {
        items: [this.getSummedGroup(val), ...val.selectedAreas],
        name: val.id,
      };
    });
  }

  customItemClass(item: AreaDto) {
    if (!item.kgs) {
      return "summary-row";
    }
    return "";
  }

  getSummedGroup(item: GetMarketAreaGroupDto): AreaDto {
    return {
      kgs: "",
      title: "Summe",
      geometry: {},
      parentKgs: "",
      areaType: "none" as AreaType,
      licensePlate: "",
      district: "",
      centroid: {},
      population: this.sumSelectedAreaField(item.selectedAreas, "population"),
      offers: this.sumSelectedAreaField(item.selectedAreas, "offers"),
      offersAppartmentBuy: this.sumSelectedAreaField(
        item.selectedAreas,
        "offersAppartmentBuy",
      ),
      offersAppartmentRent: this.sumSelectedAreaField(
        item.selectedAreas,
        "offersAppartmentRent",
      ),
      offersHouseBuy: this.sumSelectedAreaField(
        item.selectedAreas,
        "offersHouseBuy",
      ),
      offersHouseRent: this.sumSelectedAreaField(
        item.selectedAreas,
        "offersHouseRent",
      ),
      hovered: false,
    };
  }

  sumSelectedAreaField(selectedAreas: AreaDto[], field: keyof AreaDto) {
    return selectedAreas
      .map((area) => Number(area[field]) || 0)
      .reduce((a, b) => a + b, 0);
  }

  get headers(): DataTableHeader[] {
    if (this.activeArea === AreaType.plz) {
      return [{ text: "Name", value: "title", sortable: false }];
    }
    const tableHeaders: DataTableHeader[] = [
      { text: "Wählen", value: "", sortable: false, width: "50px" },
      { text: "Name", value: "title", sortable: false, width: "100px" },
      { text: "Größe", value: "areaType", sortable: false, align: "center" },
      {
        text: "Anz. WK",
        value: "offersAppartmentBuy",
        sortable: false,
        align: "end",
      },
      {
        text: "Anz. WM",
        value: "offersAppartmentRent",
        sortable: false,
        align: "end",
      },
      {
        text: "Anz. HK",
        value: "offersHouseBuy",
        sortable: false,
        align: "end",
      },
      {
        text: "Anz. HM",
        value: "offersHouseRent",
        sortable: false,
        align: "end",
      },
      { text: "Einw.", value: "population", sortable: false, align: "end" },
      { text: "", value: "actions", sortable: false, align: "end" },
    ];
    return tableHeaders.filter((item) => {
      if (item.value.startsWith("offers")) {
        return this.showEvalAmount;
      }
      if (
        this.isAreaReadOnly(this.initialMarkedArea) &&
        item.value === "actions"
      ) {
        return false;
      }
      return true;
    });
  }

  formatNumber(
    value: number,
    minimumFractionDigits: number = 0,
    maximumFractionDigits: number = 0,
  ) {
    return formatNumber(value, minimumFractionDigits, maximumFractionDigits);
  }
}
