


































































import Vue from "vue";
import Component from "vue-class-component";
import {
  AreaDto,
  GetMarketAreaGroupDto,
  MarketAreaGetDto,
} from "../../api/types";
import { marketArea } from "../../store";
import { calculateMarketAreaIndices } from "../../store/helpers";
import {
  MarketAreaActions,
  MarketAreaMutations,
  MarketAreaState,
} from "../../store/marketarea/types";
import PrintMap from "./PrintMap.vue";

@Component({
  components: {
    PrintMap,
  },
})
export default class Layout extends Vue {
  @marketArea.Action(MarketAreaActions.getInitialMarkedArea)
  getInitialMarkedArea!: (payload: { id: string; detail: boolean }) => boolean;

  @marketArea.Mutation(MarketAreaMutations.setShowEvalAmount)
  setShowEvalAmount!: (type: boolean) => void;

  @marketArea.State(MarketAreaState.initialMarkedArea)
  initialMarkedArea!: MarketAreaGetDto;

  @marketArea.State(MarketAreaState.loadingInitialArea)
  loadingInitialArea!: boolean;

  printing = false;
  async created() {
    this.getInitialMarkedArea({ id: this.$route.params.id, detail: true });
  }

  groupIndex(groupIdx: number, areaIdx: number): string {
    return calculateMarketAreaIndices(
      this.initialMarkedArea,
      groupIdx,
      areaIdx,
    );
  }
  areaTitle(group: GetMarketAreaGroupDto, area: AreaDto): string {
    let title = area.title;
    if (!group.isDefault) {
      title = `${group.title} - ${title}`;
    } else if (area.kgs.length > 8) {
      title = `${area.licensePlate} - ${title}`;
    }
    return title;
  }
}
