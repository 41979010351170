





















































































































































































import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { AreaType } from "../../api/types";
import { AreaMixin } from "../../mixins/area";
import { RoleMixin } from "../../mixins/role";
import { marketArea } from "../../store";
import {
  MarketAreaActions,
  MarketAreaMutations,
  MarketAreaState,
} from "../../store/marketarea/types";
import MainMap from "./MainMap.vue";
import MarketList from "./MarketList.vue";
import MarketPricing from "./MarketPricing.vue";
import SaveMarketAreaDialog from "./SaveMarketAreaDialog.vue";
import Toolbar from "./Toolbar.vue";

@Component({
  components: {
    MainMap,
    MarketList,
    MarketPricing,
    SaveMarketAreaDialog,
    Toolbar,
  },
})
export default class Layout extends mixins(RoleMixin, AreaMixin) {
  hidePriceCalculator = false;
  hideSubmarketList = false;
  toggle_multiple = [0];

  readonly priceCalculatorWidth = 18;
  readonly submarketListWidth = 32;
  readonly reduceWidthAmount = 13;

  @marketArea.Action(MarketAreaActions.getInitialMarkedArea)
  getInitialMarkedArea!: (payload: { id: string; detail: boolean }) => boolean;

  @marketArea.State(MarketAreaState.showTraffic)
  showTraffic!: boolean;

  @marketArea.State(MarketAreaState.showEvalAmount)
  showEvalAmount!: boolean;

  @marketArea.State(MarketAreaState.loadingInitialArea)
  loadingInitialArea!: boolean;

  @marketArea.Mutation(MarketAreaMutations.setShowTraffic)
  setShowTraffic!: (type: boolean) => void;

  @marketArea.Mutation(MarketAreaMutations.setShowEvalAmount)
  setShowEvalAmount!: (type: boolean) => void;

  @marketArea.State(MarketAreaState.activeArea)
  activeArea!: AreaType;

  async created() {
    if (!this.isAdmin) {
      this.setShowTraffic(true);
    }
    this.setShowEvalAmount(true);
    await this.getInitialMarkedArea({
      id: this.$route.params.id,
      detail: false,
    });
  }

  get contentPaddingRight() {
    if (this.hidePriceCalculator && this.hideSubmarketList) {
      return "calc(2 * 106px)";
    } else if (this.hidePriceCalculator && !this.hideSubmarketList) {
      return `calc(56px + ${
        this.submarketListWidth -
        Number(!this.showEvalAmount) * this.reduceWidthAmount
      }%)`;
    } else if (!this.hidePriceCalculator && this.hideSubmarketList) {
      return `calc(56px + ${this.priceCalculatorWidth}%)`;
    }
    return `calc(${this.priceCalculatorWidth}% + ${
      this.submarketListWidth -
      Number(!this.showEvalAmount) * this.reduceWidthAmount
    }%)`;
  }

  @Watch("hidePriceCalculator")
  watchPriceCalc() {
    (this.$refs.map as MainMap).updateSize();
  }

  @Watch("showEvalAmount")
  watchShowEvalAmount() {
    (this.$refs.map as MainMap).updateSize();
  }

  @Watch("hideSubmarketList")
  watchSubmarketList() {
    (this.$refs.map as MainMap).updateSize();
  }
}
