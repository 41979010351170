
































import { privateEncrypt } from "node:crypto";
import Vue from "vue";
import Component from "vue-class-component";
import { State } from "vuex-class";
import { AreaDto, GetMarketAreaGroupDto } from "../../api/types";
import { currencyFormatter } from "../../plugins/formatter";
import { marketArea, Prices } from "../../store";
import { MarketAreaGetters } from "../../store/marketarea/types";

@Component({})
export default class MarketPricing extends Vue {
  @marketArea.Getter(MarketAreaGetters.marketAreaGroups)
  marketAreaGroups!: GetMarketAreaGroupDto[];

  @State("prices") prices!: Prices;

  formatCurrency(price: number) {
    return currencyFormatter.format(price);
  }

  get headers() {
    return [
      { text: "Pos.", value: "pos", sortable: false },
      { text: "Stück", value: "amount", sortable: false, align: "end" },
      {
        text: "Preis (netto)",
        value: "price",
        sortable: false,
        align: "end",
      },
    ];
  }

  get items() {
    return [
      {
        pos: "Grundgebühr",
        amount: 1,
        price: this.prices.basePrice,
      },
      {
        pos: "Teilmärkte",
        amount: this.amount,
        price: this.prices.pricePerUnit * this.amount,
      },
    ];
  }
  get defaultGroup() {
    return this.marketAreaGroups.find((group) => group.isDefault)!;
  }

  get amount() {
    return (
      this.marketAreaGroups.filter(
        (group) => !group.isDefault && group.selectedAreas?.length,
      ).length + this.defaultGroup?.selectedAreas.length || 0
    );
  }

  get totalPrice() {
    return this.prices.basePrice + this.amount * this.prices.pricePerUnit;
  }
}
