var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticStyle:{"height":"100vh","width":"100vw"}},[_c('app-bar'),(_vm.loadingInitialArea)?_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"primary"}})],1)],1):[_c('v-navigation-drawer',{attrs:{"right":"","app":"","clipped":"","touchless":"","disable-resize-watcher":"","mini-variant":_vm.hidePriceCalculator,"width":(_vm.priceCalculatorWidth + "%")}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"left":""}},[_c('v-btn',{attrs:{"icon":"","left":""},on:{"click":function($event){_vm.hidePriceCalculator = !_vm.hidePriceCalculator}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.hidePriceCalculator ? "mdi-eye-outline" : "mdi-eye-off-outline")+" ")])],1)],1),_c('v-list-item-title',[_vm._v("Preise")])],1),_c('v-divider'),_c('v-expand-x-transition',{attrs:{"mode":"in-out"}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidePriceCalculator),expression:"!hidePriceCalculator"}],staticClass:"px-0 pt-1",staticStyle:{"max-height":"calc(100% - 60px)","align-items":"inherit"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',{staticClass:"mt-n4",attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('market-pricing')],1)],1),(!_vm.isAreaReadOnly(_vm.initialMarkedArea))?_c('v-row',{attrs:{"justify":"center","align":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('save-market-area-dialog')],1)],1):_vm._e()],1)],1)],1),_c('v-navigation-drawer',{style:('margin-right:' +
        (_vm.hidePriceCalculator ? '57px' : (_vm.priceCalculatorWidth + "%")) +
        '; transition: margin 200ms cubic-bezier(0.4, 0, 0.2, 1)'),attrs:{"right":"","app":"","touchless":"","clipped":"","disable-resize-watcher":"","width":((_vm.submarketListWidth - Number(!_vm.showEvalAmount) * _vm.reduceWidthAmount) + "%"),"mini-variant":_vm.hideSubmarketList}},[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"left":""}},[_c('v-btn',{attrs:{"icon":"","left":"","plain":""},on:{"click":function($event){_vm.hideSubmarketList = !_vm.hideSubmarketList}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.hideSubmarketList ? "mdi-eye-outline" : "mdi-eye-off-outline")+" ")])],1)],1),_c('v-list-item-title',[_vm._v("Teilmärkte")]),(_vm.activeArea !== 'plz' && _vm.isAdmin)?_c('v-btn-toggle',{attrs:{"multiple":"","dense":""},model:{value:(_vm.toggle_multiple),callback:function ($$v) {_vm.toggle_multiple=$$v},expression:"toggle_multiple"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.setShowEvalAmount(!_vm.showEvalAmount)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.showEvalAmount ? "mdi-eye-outline" : "mdi-eye-off-outline"))])],1)]}}],null,false,3303287569)},[_c('span',[_vm._v(" "+_vm._s(_vm.showEvalAmount ? "Anz. Angebote ausblenden" : "Anz. Angebote einblenden"))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.setShowTraffic(!_vm.showTraffic)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.showTraffic ? "mdi-traffic-light" : "mdi-traffic-light-outline"))])],1)]}}],null,false,1304989633)},[_c('span',[_vm._v(_vm._s(_vm.showTraffic ? "Angebote Ampel ausblenden" : "Angebote Ampel einblenden"))])])],1):(_vm.activeArea !== 'plz' && !_vm.isAdmin)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [(!_vm.showTraffic)?_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.setShowTraffic(true)}}},on),[_c('v-icon',[_vm._v("mdi-eye-outline")])],1):_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.setShowTraffic(false)}}},on),[_c('v-icon',[_vm._v("mdi-eye-off-outline")])],1)]}}])},[(!_vm.showTraffic)?_c('span',[_vm._v(" Anz. Angebote einblenden ")]):_c('span',[_vm._v(" Anz. Angebote ausblenden ")])]):_vm._e()],1),_c('v-divider'),_c('v-expand-x-transition',{attrs:{"mode":"in-out"}},[_c('market-list',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideSubmarketList),expression:"!hideSubmarketList"}]})],1)],1),_c('v-main',{style:('padding-right: ' + _vm.contentPaddingRight)},[_c('toolbar'),_c('v-container',{staticClass:"pa-0",staticStyle:{"position":"relative"},attrs:{"fluid":"","fill-height":""}},[_c('main-map',{ref:"map"})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }