var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_vm._v("Speichern")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"headline primary lighten-2 white--text",domProps:{"innerHTML":_vm._s(
          _vm.initialMarkedArea && _vm.initialMarkedArea.id
            ? 'Marktgebiet speichern'
            : 'Neues Marktgebiet anlegen'
        )}}),_c('v-card-text',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name *","outlined":"","error-messages":errors},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Kunde","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.items,"loading":_vm.isLoading,"search-input":_vm.search,"append-icon":"mdi-search","return-object":"","outlined":"","label":"Kunde *","item-value":"id","item-text":"companyName","filter":function () { return true; },"disabled":!_vm.isAdmin,"clearable":"","hide-no-data":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})]}}],null,true)})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Abbrechen")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.loading,"disabled":invalid},on:{"click":_vm.save}},[_vm._v(" Speichern ")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }