


















































































import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { getCustomerById, getCustomers } from "../../api/resources";
import {
  AreaType,
  CreateMarketAreaDto,
  MarketAreaGetDto,
} from "../../api/types";
import { marketArea } from "../../store";
import { timeout } from "../../store/helpers";
import {
  MarketAreaActions,
  MarketAreaState,
  SaveAreaPayload,
} from "../../store/marketarea/types";
import { Customer } from "../../store/marketareaoverview";
import { RoleMixin } from "../../mixins/role";

@Component({})
export default class SaveMarketAreaDialog extends mixins(RoleMixin) {
  @marketArea.State(MarketAreaState.initialMarkedArea)
  initialMarkedArea!: MarketAreaGetDto;

  @marketArea.Action(MarketAreaActions.saveMarketArea)
  saveMarketArea!: (payload: SaveAreaPayload) => Promise<void>;

  dialog = false;
  loading = false;
  selectedCustomer: Customer | null = null;
  title?: string = "";

  items: any[] = [];
  isLoading = false;
  search = "";

  async mounted() {
    if (!this.isAdmin) {
      this.selectedCustomer = (
        await getCustomerById("" + this.$auth.token?.customer)
      ).data;
      this.items.push(this.selectedCustomer as any);
    }
    this.initDialogValues();
  }

  @Watch("search")
  async searchWatch() {
    if (this.isAdmin) {
      if (this.isLoading || !this.search) return;
      this.isLoading = true;
      try {
        this.items = (await getCustomers(this.search)).data.items;
      } catch (error) {
        console.error("failed to get customers " + error);
        this.items = [this.selectedCustomer];
      } finally {
        this.isLoading = false;
      }
    }
  }

  async save() {
    this.loading = true;
    try {
      this.saveMarketArea({
        id: this.initialMarkedArea?.id,
        marketArea: {
          title: this.title!,
          customerId: this.selectedCustomer!.id,
          customerName: this.selectedCustomer!.companyName,
          marketAreaGroups: [], // will be set in action
          activeArea: AreaType.kgs2, // will be set in action
        },
      });
      await timeout(200);
    } finally {
      this.loading = false;
      this.dialog = false;
    }
  }

  @Watch("initialMarkedArea")
  async initDialogValues() {
    if (this.initialMarkedArea) {
      const customer = await (
        await getCustomerById(this.initialMarkedArea.customerId!)
      ).data;
      this.selectedCustomer = customer;
      this.search = customer.companyName;
      this.title = this.initialMarkedArea.title;
    }
  }
}
