











































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { latLng, LatLng, PointExpression } from "leaflet";
import { LMap, LTooltip, LMarker, LGeoJson } from "vue2-leaflet";

import { marketArea } from "../../store";
import {
  AreaTypeMapWeight,
  GroupedArea,
  MarketAreaGetters,
  MarketAreaState,
} from "../../store/marketarea/types";
import {
  AreaDto,
  AreaType,
  GetMarketAreaGroupDto,
  MarketAreaGetDto,
} from "../../api/types";
import {
  calculateMarketAreaIndices,
  getRandomMaterialColorFromText,
} from "../../store/helpers";
import { AllGeoJSON, centroid } from "@turf/turf";

@Component({
  components: {
    LMap,
    LMarker,
    LTooltip,
    LGeoJson,
  },
})
export default class MainMap extends Vue {
  zoom = 13;
  readonly boundsPadding: PointExpression = [2.5, 2.5];
  readonly boundsPaddingBottomRight: PointExpression = [2.5, 2.5];
  readyComponents = 0;

  @marketArea.State(MarketAreaState.currentMap) currentMap!: string;
  @marketArea.State(MarketAreaState.activeLayer) activeLayer!: string;
  @marketArea.State(MarketAreaState.currentBounds) currentBounds!: GeoJSON.BBox;
  @marketArea.State(MarketAreaState.activeArea) activeArea!: AreaType;
  @marketArea.State(MarketAreaState.initialMarkedArea)
  initialMarkedArea!: MarketAreaGetDto;
  @marketArea.Getter(MarketAreaGetters.areasWithGroup)
  areasWithGroup!: GroupedArea[];

  mapOptions = {
    zoomSnap: 0,
    center: new LatLng(51.133481, 10.018343),
    attributionControl: false,
  };

  created() {
    document.body.style.width = "21cm";
    document.body.style.height = "60.7cm";

    // window.addEventListener("afterprint", event => {
    //   this.$router.replace({ name: "dashboard" });
    // });
  }

  mapReadyEvent() {
    const map = (this.$refs.map as LMap).mapObject;
    // if there are already selected elements set bounding box around them
    if (this.initialMarkedArea && this.initialMarkedArea.boundingBox) {
      map.fitBounds(this.initialMarkedArea.boundingBox, {
        paddingTopLeft: this.boundsPadding,
        paddingBottomRight: this.boundsPaddingBottomRight,
        animate: false,
        duration: 0,
      });
    }
    setTimeout(() => window.print(), 1000);
  }

  onResize() {
    const map = (this.$refs.map as LMap).mapObject;
    map.fitBounds(this.initialMarkedArea.boundingBox, {
      paddingTopLeft: this.boundsPadding,
      paddingBottomRight: this.boundsPaddingBottomRight,
      animate: false,
      duration: 0,
    });
  }

  selectedAreasStyle(entry: AreaDto, groupIdx: number, areaIdx: number) {
    return (feature: any) => {
      const group = this.initialMarkedArea.marketAreaGroups[groupIdx];

      const color = getRandomMaterialColorFromText(
        group.id + "_" + group.title,
      );

      return {
        weight: AreaTypeMapWeight[entry.areaType],
        color: group.isDefault ? "#990033" : color,
        fillColor: "#A5A5A5",
        fillOpacity: 0.7,
      };
    };
  }

  latlngFromArea(area: AreaDto) {
    let geojson;
    if (area.centroid) {
      geojson = area.centroid as GeoJSON.Point;
    } else {
      const point = centroid(area.geometry as AllGeoJSON);
      geojson = point.geometry;
    }
    return latLng(geojson.coordinates[1], geojson.coordinates[0]);
  }

  getTooltip(entry: AreaDto, groupIdx: number, areaIdx: number) {
    return calculateMarketAreaIndices(
      this.initialMarkedArea,
      groupIdx,
      areaIdx,
    );
  }

  selectedAreasOptions(entry: AreaDto, groupIdx: number, areaIdx: number) {
    return {
      onEachFeature: (feature: any, layer: L.Layer) => {
        // layer.bindTooltip(
        //   `<span class="text-subtitle-1 font-weight-bold primary--text">${tooltip}<span>`,
        //   {
        //     permanent: true,
        //     direction: "center",
        //   },
        // );
      },
    };
  }

  coordinatesForArea(entry: AreaDto, groupIdx: number, areaIdx: number) {
    if (!entry) {
      return null;
    }
    return { ...entry.geometry, properties: { entry, groupIdx, areaIdx } };
  }

  sortedMarketAreaGroups(group: GetMarketAreaGroupDto): AreaDto[] {
    return group.selectedAreas.sort((a, b) => a.kgs.length - b.kgs.length);
  }
}
