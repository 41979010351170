



















































































































































































































import Component, { mixins } from "vue-class-component";
import { RoleMixin } from "../../mixins/role";
import { marketArea } from "../../store";
import {
  MarketAreaActions,
  MarketAreaGetters,
  MarketAreaMutations,
  MarketAreaState,
} from "../../store/marketarea/types";

@Component({})
export default class Toolbar extends mixins(RoleMixin) {
  @marketArea.State(MarketAreaState.currentMap) currentMap!: string;
  @marketArea.State(MarketAreaState.activeLayer) activeLayer!: string;
  @marketArea.State(MarketAreaState.activeArea) activeArea!: string;
  @marketArea.State(MarketAreaState.showDetail) showDetail!: boolean;
  @marketArea.State(MarketAreaState.additionalVisibleLayers)
  additionalVisibleLayers!: string[];

  @marketArea.Getter(MarketAreaGetters.loadAreas)
  loadAreas!: boolean;

  @marketArea.Action(MarketAreaActions.updateActiveArea)
  updateActiveArea!: (val: string) => void;

  @marketArea.Action(MarketAreaActions.toggleAdditionalVisibleLayer)
  toggleAdditionalVisibleLayer!: (val: string) => void;

  @marketArea.Action(MarketAreaActions.showDetailView)
  showDetailView!: (payload: { detail: boolean }) => void;

  @marketArea.Mutation(MarketAreaMutations.setCurrentMap)
  setCurrentMap!: (val: string) => void;
  @marketArea.Mutation(MarketAreaMutations.setActiveLayer)
  setActiveLayer!: (val: string) => void;

  created() {
    if (!this.isAdmin) {
      this.setActiveLayer("pricelayer_none");
    }
  }

  get maps() {
    return [
      {
        text: this.$t("map.layer.iibmap"),
        value: "iibmap",
      },
      {
        text: this.$t("map.layer.iibsw"),
        value: "iibsw",
      },
      {
        text: this.$t("map.layer.osm"),
        value: "osm",
      },
    ];
  }

  get priceLayers() {
    return [
      {
        text: this.$t("map.layer.pricelayer_hk"),
        value: "pricelayer_hk",
      },
      {
        text: this.$t("map.layer.pricelayer_wk"),
        value: "pricelayer_wk",
      },
      {
        text: this.$t("map.layer.pricelayer_hm"),
        value: "pricelayer_hm",
      },
      {
        text: this.$t("map.layer.pricelayer_wm"),
        value: "pricelayer_wm",
      },
      {
        text: this.$t("map.layer.pricelayer_none"),
        value: "pricelayer_none",
      },
    ];
  }
}
