







































































import L, { control, LatLngBounds } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-lasso";
import { Component, Watch } from "vue-property-decorator";
import {
  LControlLayers,
  LGeoJson,
  LMap,
  LMarker,
  LTileLayer,
} from "vue2-leaflet";
import VGeosearch from "vue2-leaflet-geosearch";

import { mixins } from "vue-class-component";
import { AreaDto, AreaType, MarketAreaGetDto } from "../../api/types";
import { AreaMixin } from "../../mixins/area";
import { RoleMixin } from "../../mixins/role";
import { marketArea } from "../../store";
import { getRandomMaterialColorFromText } from "../../store/helpers";
import {
  AreaTypeMapBoundaries,
  GroupedArea,
  MarketAreaActions,
  MarketAreaGetters,
  MarketAreaState,
} from "../../store/marketarea/types";
import LNonTiledLayerWMS from "../leaflet/LNonTiledLayerWMS.vue";

@Component({
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LGeoJson,
    LControlLayers,
    "l-wms-tile-layer": LNonTiledLayerWMS,
    VGeosearch,
  },
})
export default class MainMap extends mixins(RoleMixin, AreaMixin) {
  $refs!: {
    map: LMap;
    additionalVisibleAreasRef: LGeoJson;
    selectedAreasRef: LGeoJson;
  };

  zoom = 12;
  geosearchOptions = {
    provider: new OpenStreetMapProvider(),
  };

  @marketArea.State(MarketAreaState.currentMap) currentMap!: string;
  @marketArea.State(MarketAreaState.activeLayer) activeLayer!: string;
  @marketArea.State(MarketAreaState.currentBounds) currentBounds!: GeoJSON.BBox;
  @marketArea.State(MarketAreaState.activeArea) activeArea!: AreaType;
  @marketArea.State(MarketAreaState.showDetail) showDetail!: boolean;

  @marketArea.Getter(MarketAreaGetters.areasWithGroup)
  areasWithGroup!: GroupedArea[];
  @marketArea.Getter(MarketAreaGetters.unselectedAreas)
  unselectedAreas!: AreaDto[];

  @marketArea.State(MarketAreaState.additionalVisibleAreas)
  additionalVisibleAreas!: AreaDto[];

  @marketArea.State(MarketAreaState.detailAreas)
  detailAreas!: GroupedArea[];

  @marketArea.Action(MarketAreaActions.updateMapBounds)
  updateMapBounds!: (bounds: LatLngBounds) => void;
  @marketArea.Action(MarketAreaActions.updateMapZoom)
  updateMapZoom!: (zoom: number) => void;
  @marketArea.Action(MarketAreaActions.toggleSelectedArea)
  toggleSelectedArea!: (payload: { area: AreaDto; delete: boolean }) => boolean;
  @marketArea.Action(MarketAreaActions.addToSelectedArea)
  addToSelectedArea!: (areasToAdd: AreaDto[]) => boolean;

  wmsLayerBaseUrl = "https://map.iib-institut.de/atlas/service";
  tileLayers = [
    {
      name: "iibmap",
      layers: "iibmap",
      url:
        "https://api.mapbox.com/styles/v1/iibmaps/ckyirmvvuxj8q14pezcl6odmp/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaWlibWFwcyIsImEiOiJja3R5MXV1d3EyOW01MnBxZXlydTdrMzhhIn0.1cL_lSwNWHr9mz0W72J-5Q",
      attribution: ` | Copyright(C) ${new Date().getFullYear()} by <a href="http://www.iib-institut.de/impressum/" target="_blank" title="Wohnlagenkarte">iib-institut.de</a>  © <a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a>  © <a href="http://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a>`,
    },
    {
      name: "iibsw",
      layers: "iibsw",
      url:
        "https://api.mapbox.com/styles/v1/iibmaps/cks7lry4327vm17p4s24u5spd/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaWlibWFwcyIsImEiOiJja3R5MXV1d3EyOW01MnBxZXlydTdrMzhhIn0.1cL_lSwNWHr9mz0W72J-5Q",
      attribution: ` | Copyright(C) ${new Date().getFullYear()} by <a href="http://www.iib-institut.de/impressum/" target="_blank" title="Wohnlagenkarte">iib-institut.de</a>  © <a href="https://www.mapbox.com/about/maps" target="_blank">Mapbox</a>  © <a href="http://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a>`,
    },
    {
      name: "osm",
      layers: "osm",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },
  ];
  iibmapLayers = [
    L.tileLayer("https://prod.gcp-maps.iib-it.de/areas/{z}/{x}/{y}.png", {
      tms: true,
      zIndex: 100,
    }), // areas
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/iibmaps/ckv7tb68n8jzh15np8fd28vtv/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiaWlibWFwcyIsImEiOiJja3R5MXV1d3EyOW01MnBxZXlydTdrMzhhIn0.1cL_lSwNWHr9mz0W72J-5Q",
      { zIndex: 101 },
    ), // foreground
    L.tileLayer("https://prod.gcp-maps.iib-it.de/ratings/{z}/{x}/{y}.png", {
      tms: true,
      zIndex: 102,
    }), // ratings
  ];
  wmsLayers = [
    {
      name: "pricelayer_hk",
      layers: "pricelayer_hk",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_wk",
      layers: "pricelayer_wk",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_hm",
      layers: "pricelayer_hm",
      format: "image/png",
      transparent: true,
    },
    {
      name: "pricelayer_wm",
      layers: "pricelayer_wm",
      format: "image/png",
      transparent: true,
    },
  ];

  mapOptions = {
    zoomSnap: 0.5,
  };

  mapReadyEvent() {
    const map = this.$refs.map.mapObject;
    map.createPane("background");
    // if there are already selected elements set bounding box around them
    if (this.initialMarkedArea?.boundingBox) {
      map.fitBounds(this.initialMarkedArea.boundingBox, {
        padding: [100, 100],
      });
    } else {
      // when no previous bounds are set position to center of germany
      const initialBounds: LatLngBounds = this.currentBounds
        ? new LatLngBounds([
            [this.currentBounds[0], this.currentBounds[1]],
            [this.currentBounds[2], this.currentBounds[3]],
          ])
        : new LatLngBounds([[51.133481, 10.018343]]);
      map.fitBounds(initialBounds);
    }
    this.updateMapZoomBoundaries(this.activeArea);
    if (!this.isAreaReadOnly(this.initialMarkedArea)) {
      control.lasso({ intersect: true }).addTo(map);
      map.on("lasso.finished", (event: any) => {
        const selectedAreas: AreaDto[] = event.layers.map((layer: any) => {
          return layer.feature.geometry.properties.entry.area
            ? layer.feature.geometry.properties.entry.area
            : layer.feature.geometry.properties.entry;
        });
        this.addToSelectedArea(selectedAreas);
      });
    }
    this.updateMapLayers();
  }

  updateSize() {
    setTimeout(() => {
      this.$refs.map.mapObject.invalidateSize();
    }, 200);
  }

  get unselectedAreasStyle() {
    return () => {
      return {
        weight: 2,
        color: "#004C4C",
        fillOpacity: 0,
      };
    };
  }

  get additionalVisibleAreaStyle() {
    return () => {
      return {
        weight: 4,
        color: "red",
        fillOpacity: 0.05,
      };
    };
  }

  get selectedAreasStyle() {
    return (feature: any) => {
      const area = feature.geometry.properties.entry as GroupedArea;

      const color = getRandomMaterialColorFromText(
        area.group.id + "_" + area.group.title,
      );
      const highlighColor = "#bbff00";
      return {
        weight: area.area.hovered ? 6 : 4,
        color: area.area.hovered
          ? highlighColor
          : area.group.isDefault
          ? "#004C4C"
          : color,
        fillOpacity: area.area.hovered ? 0.3 : 0.15,
        fill: true,
      };
    };
  }

  get areasOptions() {
    return {
      onEachFeature: (feature: any, layer: any) => {
        const area = feature.properties.entry as AreaDto;

        let tooltip = area.title;
        if (
          area.areaType === AreaType.kgs11 ||
          area.areaType === AreaType.kgs14
        ) {
          tooltip = area.licensePlate + " - " + tooltip;
        }

        tooltip = `${this.$t(`areaType.abbreviation.${area.areaType}`)} - ${
          area.kgs
        } - ${tooltip}`;

        layer.bindTooltip(tooltip);

        if (!this.isAreaReadOnly(this.initialMarkedArea) && !this.showDetail) {
          layer.on("click", () =>
            this.toggleSelectedArea({ area, delete: false }),
          );
        }
      },
    };
  }

  get selectedAreasOptions() {
    return {
      onEachFeature: (feature: any, layer: any) => {
        const group = feature.properties.entry as GroupedArea;
        let tooltip = group.area.title;
        if (
          group.area.areaType === AreaType.kgs11 ||
          group.area.areaType === AreaType.kgs14
        ) {
          group.area.licensePlate + " - " + tooltip;
        }
        layer.bindTooltip(
          `${this.$t(
            `areaType.abbreviation.${group.area.areaType}`,
          )} - ${tooltip}`,
        );
        if (!this.isAreaReadOnly(this.initialMarkedArea) && !this.showDetail) {
          layer.on("click", () =>
            this.toggleSelectedArea({ area: group.area, delete: false }),
          );
        }
      },
    };
  }

  get additionalVisibleAreaOptions() {
    return {
      onEachFeature: (feature: any, layer: any) => {
        const area = feature.properties.entry as AreaDto;
        let tooltip = area.title;
        if (
          area.areaType === AreaType.kgs11 ||
          area.areaType === AreaType.kgs14
        ) {
          tooltip = area.licensePlate + " - " + tooltip;
        }
        layer.bindTooltip(
          `${this.$t(`areaType.abbreviation.${area.areaType}`)} - ${tooltip}`,
        );
      },
    };
  }

  get areasGeometry() {
    return {
      type: "FeatureCollection",
      features: this.unselectedAreas.map((tile) => {
        return {
          type: "Feature",
          ...this.coordinatesForArea(tile),
        };
      }),
    };
  }

  get additionalVisibleAreaGeometry() {
    return {
      type: "FeatureCollection",
      features: this.additionalVisibleAreas.map((tile) => {
        return {
          type: "Feature",
          ...this.coordinatesForArea(tile),
        };
      }),
    };
  }

  get selectedAreasGeometry() {
    return {
      type: "FeatureCollection",
      features: this.areasWithGroup.map((tile) => {
        return {
          type: "Feature",
          ...this.coordinatesForGroup(tile),
        };
      }),
    };
  }
  get detailAreasGeometry() {
    return {
      type: "FeatureCollection",
      features: this.detailAreas.map((tile) => {
        return {
          type: "Feature",
          ...this.coordinatesForGroup(tile),
        };
      }),
    };
  }

  coordinatesForArea(entry: AreaDto) {
    if (!entry) {
      return null;
    }
    return { ...entry.geometry, properties: { entry } };
  }

  coordinatesForGroup(entry: GroupedArea) {
    if (!entry) {
      return null;
    }
    return { ...entry.area.geometry, properties: { entry } };
  }

  @Watch("activeArea")
  updateMapZoomBoundaries(val: AreaType) {
    const map = this.$refs.map.mapObject;
    map.setMinZoom(AreaTypeMapBoundaries[val].minZoom);
    map.setZoom(AreaTypeMapBoundaries[val].initialZoom);
  }

  @Watch("initialMarkedArea")
  centerMapToInitialSelection(val: MarketAreaGetDto) {
    if (!val || val.marketAreaGroups.length === 0) {
      return;
    }

    const map = this.$refs.map.mapObject;
    map.fitBounds(val.boundingBox, {
      padding: [100, 100],
    });
  }

  @Watch(MarketAreaState.additionalVisibleAreas)
  async additionalVisibleAreasWatch(val: AreaDto[]) {
    await this.$nextTick();
    this.$refs.additionalVisibleAreasRef.mapObject.bringToBack();
    this.$refs.selectedAreasRef.mapObject.bringToFront();
  }

  @Watch(MarketAreaState.currentMap)
  updateMapLayers() {
    if (this.currentMap === "iibmap") {
      this.iibmapLayers.forEach((layer) =>
        this.$refs.map.mapObject.addLayer(layer),
      );
    } else {
      this.iibmapLayers.forEach((layer) =>
        this.$refs.map.mapObject.removeLayer(layer),
      );
    }
  }
}
