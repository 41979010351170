
// @ts-nocheck
import Vue from "vue";
import L from "leaflet";
import TileLayerWMS from "vue2-leaflet/src/mixins/TileLayerWMS";
import Options from "vue2-leaflet/src/mixins/Options";
import {
  optionsMerger,
  propsBinder,
  findRealParent,
} from "vue2-leaflet/src/utils/utils";
import { DomEvent } from "leaflet";
const nonTiledLayer = require("leaflet.nontiledlayer");

export default Vue.extend({
  name: "LNonTiledLayerWMS",
  mixins: [TileLayerWMS, Options],
  props: {
    baseUrl: {
      type: String,
      default: null,
    },
  },
  mounted() {
    const options = optionsMerger(this.tileLayerWMSOptions, this);
    this.mapObject = L.nonTiledLayer.wms(this.baseUrl, options);
    DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, this.$options.props);
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit("ready", this.mapObject);
    });
  },
});
