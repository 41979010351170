var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 pt-1",staticStyle:{"max-height":"calc(100% - 60px)","align-items":"inherit"},attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('add-area-to-group-dialog',{ref:"groupAddDialog"}),_c('v-card-text',{staticClass:"px-1 py-1"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.marketAreaGroups,"disable-pagination":"","hide-default-footer":"","group-by":"parentKgs","custom-group":_vm.groupingFunction,"group-desc":false,"item-class":_vm.customItemClass,"dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
return [_c('td',{staticClass:"text-start",staticStyle:{"background-color":"rgba(0, 76, 76, 0.18)"},attrs:{"colspan":_vm.headers.length}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":toggle}},[_c('v-icon',{style:(("transform: rotate(" + (isOpen ? '0deg' : '180deg') + ")"))},[_vm._v(" mdi-chevron-up ")])],1)],1),(_vm.groupInEdit === group && group)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","width":"256px"},model:{value:(_vm.newGroupName),callback:function ($$v) {_vm.newGroupName=$$v},expression:"newGroupName"}})],1):_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.marketAreaGroupById(group).title)+" ")]),(
                      _vm.groupInEdit !== group &&
                      !_vm.marketAreaGroupById(group).isDefault
                    )?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showEditGroupName(group)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1):(_vm.groupInEdit === group)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.saveGroupName()}}},[_c('v-icon',[_vm._v("mdi-check")])],1)],1):_vm._e(),(!_vm.marketAreaGroupById(group).isDefault)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeGroup(group)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","disabled":!_vm.selectedRows.length},on:{"click":function($event){return _vm.moveToGroup(group)}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s("Ausgewählte Bereiche in diese Gruppe verschieben"))])])],1)],1)],1)]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('tr',{on:{"mouseover":function($event){return _vm.highlightItem({ area: item, highlight: true })},"mouseleave":function($event){return _vm.highlightItem({ area: item, highlight: false })}}},[_c('td',[(item.kgs)?_c('v-checkbox',{attrs:{"dense":"","hide-details":"","input-value":!!_vm.selectedRows.find(function (i) { return i.kgs === item.kgs; })},on:{"change":function($event){return _vm.toggleRowSelection(item)}}}):_vm._e()],1),_c('td',[(item.areaType === 'plz')?[_vm._v(" "+_vm._s(_vm.$t("areaType.abbreviation.plz"))+" - "+_vm._s(item.kgs)+" - "+_vm._s(item.title)+" ")]:[(
                        item.areaType === 'kgs11' || item.areaType === 'kgs14'
                      )?_c('span',[_vm._v(_vm._s(item.licensePlate)+" - ")]):_vm._e(),_vm._v(_vm._s(item.title)+" ")]],2),_c('td',{staticStyle:{"text-align":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.$t(("areaType.abbreviation." + (item.areaType)))))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(("areaType.fullname." + (item.areaType)))))])])],1),(_vm.showEvalAmount)?_c('td',{staticStyle:{"text-align":"end"}},[_c('evaluation-amount-renderer',{attrs:{"value":item.offersAppartmentBuy}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.offersAppartmentBuy, 0, 0))+" ")])],1):_vm._e(),(_vm.showEvalAmount)?_c('td',{staticStyle:{"text-align":"end"}},[_c('evaluation-amount-renderer',{attrs:{"value":item.offersAppartmentRent}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.offersAppartmentRent, 0, 0))+" ")])],1):_vm._e(),(_vm.showEvalAmount)?_c('td',{staticStyle:{"text-align":"end"}},[_c('evaluation-amount-renderer',{attrs:{"value":item.offersHouseBuy}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.offersHouseBuy, 0, 0))+" ")])],1):_vm._e(),(_vm.showEvalAmount)?_c('td',{staticStyle:{"text-align":"end"}},[_c('evaluation-amount-renderer',{attrs:{"value":item.offersHouseRent}},[_vm._v(" "+_vm._s(_vm.formatNumber(item.offersHouseRent, 0, 0))+" ")])],1):_vm._e(),_c('td',{staticStyle:{"text-align":"end"}},[(item.population)?_c('span',[_vm._v(" "+_vm._s(_vm.formatNumber(item.population, 0, 0))+" ")]):_vm._e()]),_c('td',{staticStyle:{"text-align":"end"}},[(item.kgs)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleSelectedArea({ area: item, delete: true })}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)])]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)],1)],1),(!_vm.isAreaReadOnly(_vm.initialMarkedArea))?_c('v-row',{attrs:{"justify":"center","align":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.createMarketAreaGroup}},[_vm._v(" Neue Gruppe ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }